<template>
  <ui-link
    :url="url"
    :blank="newTab"
    :rel="rel"
    class="hover:no-underline text-ls h-full pb-2"
  >
    <card
      class="hover:border-gray-dark w-full h-full min-h-22 md:w-24 md:h-24 flex items-center flex-col justify-center py-2"
      border-radius="default"
      margin="mb-5"
    >
      <div>
        <div class="relative flex justify-center">
          <icon :icon="iconType" width="34" height="34" color="primary" />
          <span
            v-if="isNew"
            class="absolute end-50 bottom-0 text-2xs translate-x-2/3  text-2xs font-bold leading-snug bg-alert text-primary rounded-xs p-px px-1"
          >
            {{ $t('general.new') }}
          </span>
        </div>

        <p class="text-ls text-center text-primary leading-tight pt-2"
          >{{ title }}
        </p>
      </div>
    </card>
  </ui-link>
</template>
<script>
import { mapGetters } from 'vuex'
import VueTypes from 'vue-types'
import { Card, UiLink, Icon } from '~/components/atoms'
export default {
  components: {
    Icon,
    UiLink,
    Card,
  },
  props: {
    categoryLink: VueTypes.object,
    externalLink: VueTypes.object,
    iconType: VueTypes.string,
    isNew: VueTypes.boolean,
    title: VueTypes.string,
  },

  computed: {
    ...mapGetters('context', ['marketplacePrefix', 'isGlobalMarketplace']),

    url() {
      return this.categoryLinkUrl || this.externalLink?.url || ''
    },

    categoryLinkUrl() {
      if (!this.categoryLink) return null
      return this.isGlobalMarketplace && this.categoryLink.slug
        ? `/${this.marketplacePrefix}/${this.categoryLink.slug}`
        : this.categoryLink.url
    },

    rel() {
      return this.externalLink?.rel
    },
    newTab() {
      return this.externalLink?.newTab
    },
  },
}
</script>
